<script setup lang='ts'>
import { Icon } from '@iconify/vue'

const { $colorMode, $sanctumAuth } = useNuxtApp()
///////////////////////////////////////////////// Import, Types & meta
//////////////////////////////////////////////////////////////////////
const props = defineProps({
    lightSwitch: {
        type: Boolean,
        default: true,
    },
})
let themeMode = ref($colorMode.preference);

async function toggleColorMode() {
    themeMode.value = themeMode.value == 'light' ? 'dark' : 'light';
    $colorMode.preference = themeMode.value
}
</script>

<template>
    <div>
        <ul>
            <li class="cursor-pointer"
                @click="toggleColorMode">
                <div v-if="$colorMode.preference === 'light'">
                    <Icon icon="material-symbols:dark-mode-outline"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="hover:bg-gray-300 rounded-full" />
                </div>
                <div v-else>
                    <Icon icon="ph:sun-dim"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="dark:hover:bg-gray-700 rounded-full" />
                </div>
            </li>
        </ul>
    </div>
</template>

<style scoped>
ul {
    margin: 0;
    padding: 0;
    position: relative;
    left: 6%;
}

ul li {
    list-style: none;
    margin: 0 15px;
}
</style>
