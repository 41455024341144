<script setup lang="ts">
import AppUrl from '~/utils/constants/AppUrl.js'
import useSanctumAuth from '~/composables/useSanctumAuth'
import { Icon } from '@iconify/vue'

const user = useUser()
const userInfo = computed(() => user.getUserDetails())
const userRole = user.getUserRoles()
const { logout } = useSanctumAuth()

// const themeMode = ref('light');
const isSideBarOpened = ref(true);
const isAvatarMenuOpened = ref(false);
const route = useRoute();

const widthBreakpoint = 821;
const version = useRuntimeConfig().public.version

const menus = {
    [AppNavigationName.DASHBOARD]: {
        to: AppUrl.PAGE_DASHBOARD,
        icon: 'heroicons:chart-pie',
        label: 'Dashboard',
    },
    [AppNavigationName.EVENTS]: {
        to: AppUrl.PAGE_EVENTS,
        icon: 'heroicons:calendar-days',
        label: 'Events',
    },
    [AppNavigationName.EVENT_GROUPS]: {
        to: AppUrl.PAGE_EVENT_GROUPS,
        icon: 'uis:layer-group',
        label: 'Event Groups',
    },
    [AppNavigationName.ORDERS]: {
        to: AppUrl.PAGE_ORDERS,
        icon: 'heroicons:currency-dollar',
        label: 'Orders',
    },
    [AppNavigationName.CUSTOMERS]: {
        to: AppUrl.PAGE_CUSTOMERS,
        icon: 'heroicons:user-group',
        label: 'Customers',
    },
    [AppNavigationName.LEADS]: {
        to: AppUrl.PAGE_LEADS,
        icon: 'heroicons:funnel',
        label: 'Leads',
    },
    [AppNavigationName.COMMISSIONS]: {
        to: AppUrl.PAGE_COMMISSIONS_SIMULATION,
        icon: 'heroicons:currency-dollar',
        label: 'Commissions',
    },
    [AppNavigationName.ADMINS]: {
        to: AppUrl.PAGE_ADMINS,
        icon: 'heroicons:user-group',
        label: 'Admins',
    },
    [AppNavigationName.ORGANIZATION]: {
        to: AppUrl.PAGE_ORGANIZATION,
        icon: 'octicon:organization-16',
        label: 'Organization',
    },
    [AppNavigationName.LOG]: {
        to: AppUrl.PAGE_AUDIT_LOG,
        icon: 'mdi:folder-cog-outline',
        label: 'Log'
    },
    // Should be kept as last item
    [AppNavigationName.ACCOUNT]: {
        to: AppUrl.PAGE_ACCOUNT,
        icon: 'heroicons:user-circle',
        label: 'Account',
    },
}

const userMenus = user.getNavMenusByRole()

/**
 * Sort menu according to the order of `menus` object
 *
 * ### Example
 * ```ts
 * sortMenu('ACCOUNT', 'ORDERS', 'EVENTS')
 * // returns: ['EVENTS', 'ORDERS', 'ACCOUNT']
 * ```
 */
function sortMenu(menuToBeSorted: Array<keyof typeof AppNavigationName>) {
    const sortedMenu = []

    const unsortedMenuLookup = new Set(menuToBeSorted)
    for (const menuName in menus) {
        if (unsortedMenuLookup.has(menuName as keyof typeof AppNavigationName)) {
            sortedMenu.push(menuName)
        }
    }

    return sortedMenu
}

function updateSideBarStatus() {
    isSideBarOpened.value = window.innerWidth >= widthBreakpoint;
}

updateSideBarStatus();
window.addEventListener('resize', updateSideBarStatus);

function isActiveRoute(to: string) {
    const currentRoute = route.path;

    return currentRoute.startsWith(to);
}
</script>

<template>
    <div>
        <nav
             class="fixed top-0 z-30 w-full border-b bg-[#ffffffe6] border-[#ebebeb] dark:bg-gray-800 dark:border-gray-700 ">
            <div class="py-3 pr-2">
                <div class="flex items-center justify-between">
                    <div class="flex items-center justify-start">
                        <button data-drawer-target="logo-sidebar"
                                data-drawer-toggle="logo-sidebar"
                                aria-controls="logo-sidebar"
                                type="button"
                                class="bg-transparent dark:bg-transparent inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                            <span class="sr-only">Open sidebar</span>
                            <Icon icon="ic:round-menu"
                                  @click="isSideBarOpened = !isSideBarOpened"
                                  class="w-6 h-6"></Icon>
                        </button>
                        <a href="/"
                           class="flex ml-2 md:mr-24 mb-2.6">
                            <img src="/assets/img/vilor_logo.png"
                                 class="h-8 mr-3"
                                 alt="Vilor Logo" />
                            <span
                                  class="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white"></span>
                        </a>
                    </div>
                    <div class="flex items-center">
                        <div class="flex items-center ml-3">
                        </div>

                        <ThemeSwitch />

                        <div class="flex items-center ml-3">
                            <div>
                                <button @click="isAvatarMenuOpened = !isAvatarMenuOpened"
                                        type="button"
                                        id="cypress-dropdown-user"
                                        class="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                        aria-expanded="false"
                                        data-dropdown-toggle="dropdown-user">
                                    <span class="sr-only">Open user menu</span>
                                    <div
                                         class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                        <span class="font-medium text-gray-600 dark:text-gray-300">{{ user.getUserInitials()
                                        }}</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fixed right-1 z-30 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                     :class="{ 'hidden': !isAvatarMenuOpened }"
                     id="dropdown-user">
                    <div class="px-4 py-3"
                         role="none">
                        <p class="text-md mb-2 text-gray-900 dark:text-white"
                           role="none">
                            {{ userInfo.name }}
                        </p>
                        <p class="text-xs italic text-gray-900 truncate dark:text-gray-300"
                           role="none">
                            {{ userInfo.email }}
                        </p>
                        <p class="text-xs italic text-gray-900 truncate dark:text-gray-300"
                           id="user-role"
                           role="none">
                            {{ userRole.label }}
                        </p>
                    </div>
                    <ul class="py-1"
                        role="none">
                        <li>
                            <NuxtLink class="block px-4 py-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                      role="menuitem"
                                      :to="AppUrl.PAGE_ACCOUNT">
                                <span class="">Account</span>
                            </NuxtLink>
                        </li>
                        <li>
                            <a href="#"
                               @click="logout"
                               class="block px-4 py-4 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                               role="menuitem">Sign out</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <aside id="logo-sidebar"
               class="fixed top-0 left-0 z-29 w-64 h-screen pt-17 transition-transform border-r sm:translate-x-0 bg-[#ffffffe6] border-[#ebebeb] dark:nav-gradient dark:border-gray-700"
               :class="{ '-translate-x-full hidden': !isSideBarOpened }"
               aria-label="Sidebar">
            <div
                 class="pt-2 space-y-2 h-full flex flex-col justify-between pr-5 pb-4 overflow-y-auto bg-[#fcfcfce6] dark:nav-gradient">
                <ul class="space-y-2 font-medium">
                    <li v-for="(navName, index) in sortMenu(userMenus)"
                        :key="index">
                        <NuxtLink :to="menus[navName].to"
                                  class="viNavLink"
                                  :class="{ viNavLinkActive: isActiveRoute(menus[navName].to), viNavLinkDisabled: menus[navName].to === route.path }">
                            <Icon class="w-6 h-6"
                                  :icon="menus[navName].icon"></Icon>
                            <span class="ml-3">{{ menus[navName].label }}</span>
                        </NuxtLink>
                    </li>
                </ul>

                <ul class="font-medium">
                    <li>
                        <!-- <NuxtLink :to="AppUrl.PAGE_ACCOUNT"
                                  class="viNavLink"
                                  active-class="viNavLinkActive" >
                            <Icon class="w-6 h-6" icon="heroicons:user-20-solid" />
                            <span class="flex-1 ml-3 whitespace-nowrap">Account</span>
                        </NuxtLink> -->
                        <a href="#"
                           class="viNavLink"
                           @click="logout">
                            <Icon class="w-6 h-6"
                                  icon="material-symbols:logout-rounded"></Icon>
                            <span class="flex-1 ml-3 whitespace-nowrap">Logout</span>
                        </a>
                        <div class="text-center text-xs text-slate-500/90">{{ version }}</div>
                    </li>
                </ul>
            </div>
        </aside>

        <div class="p-1 pt-4 sm:p-4 !pt-20"
             :class="{ 'sm:ml-64': isSideBarOpened }">
            <slot></slot>
        </div>
        <BackToTop />
    </div>
</template>

<style scoped>
.dark .dark\:nav-gradient {
    background: #1f2937;
    background: -webkit-linear-gradient(to bottom, #1f2937, #2d3344);
    background: linear-gradient(to bottom, #1f2937, #2d3344);
}
</style>
